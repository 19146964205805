<template>
  <div class="mt-4">
    <div>
      <div class="vx-row mb-3">
        <div class="vx-col w-full px-0">
          <div class="flex justify-end">
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
          </div>
        </div>
      </div>

      <!--table for jenis spk unit-->
      <div v-if="isJenisSpkUnit" class="vs-component vs-con-table fix-table-input-background stripe vs-table-primary">
        <div class="con-tablex vs-table--content">
          <div class="vs-con-tbody vs-table--tbody ">
            <table class="vs-table vs-table--tbody-table">
              <thead class="vs-table--thead">
              <tr>
                <th class="vs-table-text whitespace-no-wrap">#</th>
                <th class="vs-table-text whitespace-no-wrap">Kode Pekerjaan</th>
                <th class="vs-table-text whitespace-no-wrap">Item Pekerjaan</th>
                <th class="vs-table-text whitespace-no-wrap">Bobot</th>
              </tr>
              </thead>
              <tbody v-for="(item, index) in rowsItemsSpkUnit" :key="index" class="text-sm">
              <tr class="tr-values vs-table--tr">
                <td class="td vs-table--td font-semibold cursor-pointer" colspan="4">UNIT: {{ item.no_unit }}</td>
              </tr>
              <tr class="tr-values vs-table--tr" v-for="(it, i) in item.pekerjaan" :key="i">
                <td class="td vs-table--td">{{ i + 1 }}</td>
                <td class="td vs-table--td">{{ it.kode_item_progress_pekerjaan }}</td>
                <td class="td vs-table--td">{{ it.nama_item_progress_pekerjaan }}</td>
                <td class="td vs-table--td">{{ it.bobot }}%</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--table for jenis spk non unit-->
      <vs-table v-if="isJenisSpkNonUnit" :data="rowsItemsSpkNonUnit" stripe class="fix-table-input-background">
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">Kode Pekerjaan</vs-th>
          <vs-th class="whitespace-no-wrap">Item Pekerjaan</vs-th>
          <vs-th class="whitespace-no-wrap">Bobot</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(it, i) in data" :key="i" class="text-sm">
            <vs-td class="whitespace-no-wrap">{{ i + 1 }}</vs-td>
            <vs-td>{{ it.kode_item_progress_pekerjaan }}</vs-td>
            <vs-td>{{ it.nama_item_progress_pekerjaan }}</vs-td>
            <vs-td>{{ it.bobot }}%</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <!--table for jenis spk other-->
    <table v-if="isJenisSpkOther" class="table-auto table-sticky border-collapse w-full">
        <!-----------TABLE HEAD------------>
        <thead class="font-bold">
        <tr class="d-theme-dark-bg">
          <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">#</th>
          <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center">Nama</th>
          <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center">Satuan</th>
          <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Qty</th>
          <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Harga</th>
          <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-center whitespace-no-wrap">Total</th>
        </tr>
        </thead>
        <!-----------TABLE BODY------------>
        <tbody>
        <tr v-for="(item, index) in rowsItemsSpkOther" :key="index">
          <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-left">{{ index + 1 }}</td>
          <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-left">{{ item.nama_item_pengadaan }}</td>
          <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-left">{{ item.satuan_item_pengadaan }}</td>
          <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ item.qty }}</td>
          <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ item.harga_satuan | idr }}</td>
          <td class="border border-solid d-theme-border-grey-light p-2 text-sm font-medium text-right">{{ item.harga_total | idr }}</td>
        </tr>
        </tbody>
        <!-----------TABLE FOOT------------>
        <tfoot>
        <tr class="d-theme-dark-bg">
          <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-sm font-bold text-right" colspan="6">{{ sumBy(rowsItemsSpkOther, 'harga_total') | idr }}</th>
        </tr>
        </tfoot>
      </table>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" type="border" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import constant from '@/constant/constant'

export default {
  name: 'TabPekerjaan',
  props: {
    initData: {
      default: null,
      type: Object
    }
  },
  computed: {
    isJenisSpkUnit () {
      return this.initData.header.jenis_spk === constant.JENIS_SPK.UNIT
    },
    isJenisSpkNonUnit () {
      return this.initData.header.jenis_spk === constant.JENIS_SPK.NON_UNIT
    },
    isJenisSpkOther () {
      return this.initData.header.jenis_spk !== constant.JENIS_SPK.NON_UNIT && this.initData.header.jenis_spk !== constant.JENIS_SPK.UNIT
    },
    rowsItemsSpkUnit () {
      const search = (this.search || '').toLowerCase()
      let items = this.initData.pekerjaan
      if (search != null) {
        items = _.filter(items, (item) => {
          const noUnit = item.no_unit || ''
          const kodePekerjaan = item.kode_item_progress_pekerjaan || ''
          const namaPekerjaan = item.nama_item_progress_pekerjaan || ''
          return noUnit.toLowerCase().includes(search) || kodePekerjaan.toLowerCase().includes(search) || namaPekerjaan.toLowerCase().includes(search)
        })
      }
      let itemsGrouped = _.map(_.uniqBy(items, item => item.id_properti_unit), it => _.pick(it, ['id_properti_unit', 'no_unit']))
      itemsGrouped = _.map(itemsGrouped, item => {
        item.pekerjaan = _.filter(items, it => it.id_properti_unit === item.id_properti_unit)
        return item
      })
      return itemsGrouped
    },
    rowsItemsSpkNonUnit () {
      const search = (this.search || '').toLowerCase()
      const items = this.initData.pekerjaan
      if (search != null) {
        return _.filter(items, (item) => {
          return item.kode_item_progress_pekerjaan.toLowerCase().includes(search) || item.nama_item_progress_pekerjaan.toLowerCase().includes(search)
        })
      }
      return items
    },
    rowsItemsSpkOther () {
      const search = (this.search || '').toLowerCase()
      const items = this.initData.pekerjaan
      if (search != null) {
        return _.filter(items, (item) => {
          return item.nama_item_pengadaan.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  data () {
    return {
      search: null
    }
  },
  methods: {
    sumBy (objects, key) {
      return _.sumBy(objects, key)
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.spkKontrakApproval.activeTab
      this.$store.commit('approvals/spkKontrakApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.spkKontrakApproval.activeTab
      this.$store.commit('approvals/spkKontrakApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
